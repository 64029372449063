.search {
  box-sizing: border-box;
  width: 600px;
  height: 50px;
  position: relative;
  margin: 0;
  padding: 0;

  &__field {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 120px 10px 20px;
    background: whitesmoke;
    display: block;
    border: none;
    color: var(--color-white);
    font-size: 16px;
    line-height: 1;
    font-family: "DIN";
    font-weight: normal;

    &::placeholder {
      color: var(--color-gray-normal);
    }

    &:focus {
      outline: none;
      background: whitesmoke;
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    margin-top: -18px;
    right: 7px;
  }

  &--closable {
    .search {
      &__field {
        padding-right: 160px;
      }

      &__button {
        right: 50px;
        transform: translateX(20px);
        transition: transform 450ms cubic-bezier(0.24, 1.12, 0.71, 0.98) 100ms;

        &.is-open {
          transform: translateX(0);
          transition-delay: 250ms;
        }
      }

      &__close {
        position: absolute;
        padding: 10px;
        top: 50%;
        margin-top: -21px;
        right: 4px;
        transform: scale(0.2);
        transition: transform 400ms cubic-bezier(0.24, 1.12, 0.71, 0.98);

        &.is-open {
          transform: rotate(180deg) scale(1);
          transition-delay: 300ms;
        }
      }
    }
  }
}
