@import "../../styles/sccs-vars.scss";

.quadrant-section {
  &__header {
    margin-bottom: 20px;
  }

  &__rings {
    display: flex;
    flex-wrap: wrap;
  }

  &__ring {
    box-sizing: border-box;
    padding: 0 8px;
    flex: 0 0 25%;
    margin: 0 0 25px;

    @media (max-width: $until-md) {
      flex-basis: 50%;
    }
  }
}

.icon-link:hover .zoom-in-text {
  border-radius: 50%;
  animation: pulse 1s infinite;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;       
}

@keyframes pulse {
      
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(229, 62, 62, 1);
  }

  70% {
    transform: scale(1.01);
    box-shadow: 0 0 0 10px rgba(229, 62, 62, 0);
  }

  100% {
    transform: scale(1);
  }
}
