.item-page {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  &__nav,
  &__content {
    box-sizing: border-box;
    padding-top: 130px;
    min-height: 100vh;
    position: relative;
  }

  &__header {
    min-height: 40px;
    margin: 0 0 25px;
  }

  &__nav {
    background: var(--color-gray-dark);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(((100vw - 1200px) / 2) + 400px);
  }

  &__nav__inner {
    box-sizing: border-box;
    float: right;
    width: 410px;
    padding: 0 40px 0 10px;
  }

  &__content {
    transform: translate3d(0, 0, 0);
    //flex: 0 0 calc((100vw - 1200px) / 2 + 800px);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100vw - 1200px) / 2 + 800px);
    background: var(--color-white);

    &__inner {
      box-sizing: border-box;
      width: 810px;
      padding: 0 10px 0 100px;
      margin-top: 65px;
    }
  }
}

.hero-headline__wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.mobile-item-page {
  background: #fff;
  margin: 0 -15px;
  padding: 15px;
  min-height: 300px;

  &__aside {
    padding: 20px 0;
  }
}


// circle
.approach-data {
  width: 300px;
  position: absolute;
  z-index: 1;

  @media (max-width: 640px) {
    width: 100%;
    position: relative;
    z-index: unset;
  }
}

.title-with-circle-top {
  position: relative;
  top: 45px;
  height: 55px;
}

.blue-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
  width: 350px;
  height: 350px;
  background-color: var(--elyadata-blue);
  border-radius: 50%;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;

  @media (max-width: 640px) {
    width: 25px;
    height: 25px;
    animation: pulse 1s infinite;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
  }
}

.big-pink-circle {
  position: absolute;
  height: 450px;
  width: 450px;
  z-index: 2;
  background-color: rgba(var(--elyadata-pink-rgb), 0.72);
  animation: unset;

  @media (max-width: 640px) {
    width: 265px;
    height: 265px;
  }
}

.top {
  transform: translate(-25%, 50px);

  @media (max-width: 640px) {
    transform: translate(-50%, 0%);
  }
}

.bottom {
  transform: translate(-35%, 50px);

  @media (max-width: 640px) {
    transform: translate(0%, 0%);
  }
}

.center {
  transform: translate(-30%, -100%);

  @media (max-width: 640px) {
    transform: translate(-50%, 0%);
  }
}

.approach-title {
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0px;
  position: relative;
  left: 70px;
  max-width: 230px;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;

  @media (max-width: 640px) {
    font-size: 18px;
    line-height: 23px;
    left: 35px;
  }
}

.approach-title-hover {
  left: 20px;
  @media (max-width: 640px) {
    opacity: 0;
  }
}
.approach-description-hidden {
  opacity: 0;
  z-index: -5;
   pointer-events: none;
  @media (max-width: 640px) {
    display: none;
  }
}

.approach-description {
  color: white;
  opacity: 1;
  z-index: 2;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  text-align: center;
  font-size: 20px;
  line-height: 35px;
  position: relative;
  pointer-events: none;
  position: absolute;
  display: flex;
  @media (max-width: 640px) {
    font-size: 13px;
    line-height: 16px;
  }
}
.description-top {
  width: 360px;

  transform: translate(-20%, 20%);
  @media (max-width: 640px) {
    width: 220px;
    transform: translate(-50%, 25%);
  }
}
.description-center {
  width: 360px;

  transform: translate(-25%, -135%);
  @media (max-width: 640px) {
    width: 220px;
    transform: translate(-50%, 30%);
  }
}
.description-bottom {
  width: 360px;

  transform: translate(-32%, 30%);
  @media (max-width: 640px) {
    width: 220px;
    transform: translate(10%, 35%);
  }
}

.approach-description:not(:hover) {
  transition: all 0s;
  -webkit-transition: all 0s;
}

.approach-description-top {
  transform: translate(0, -500px);

  @media (max-width: 640px) {
    transform: unset;
  }
}

.approach-title-inside-hidden {
  display: none;
}

.approach-title-inside {
  display: none;

  @media (max-width: 640px) {
    display: flex;
    font-size: 18px;
    line-height: 23px;
    font-weight: 800;
    top: 20px;
    position: relative;
    justify-content: center;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(229, 62, 62, 1);
  }

  70% {
    transform: scale(1.01);
    box-shadow: 0 0 0 10px rgba(229, 62, 62, 0);
  }

  100% {
    transform: scale(1);
  }
}

.approach-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  margin-top: 10%;
  left: -100px;
}

::-webkit-scrollbar {
  width: 3px;
}