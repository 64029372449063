.badge {
  color: #040077;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 15px;
  text-transform: uppercase;
  border-radius: 13px;
  font-size: 12px;
  line-height: 25px;
  height: 25px;
  overflow: hidden;
  text-decoration: none;
  border: 1px solid var(--color-gray-normal);

  &--big {
    border-radius: 15px;
    font-size: 14px;
    line-height: 30px;
    height: 30px;
    padding: 0 20px;
  }

  &--all {
    background: whitesmoke;
    border-color: var(--color-gray-normal);
  }
  &--first {
    background: var(--color-green);
    border-color: var(--color-green);
  }
  &--second {
    background: var(--color-orange);
    border-color: var(--color-orange);
  }
  &--third {
    background: var(--color-blue);
    border-color: var(--color-blue);
  }
  &--fourth {
    background: var(--color-marine);
    border-color: var(--color-marine);
  }
}
