.chart {
    fill: black;
    font-size: 12px;
    text-align: center;
    position: relative;
    margin: 0 auto;
}

.chart .blip:hover {
    cursor: pointer;
}

.chart .tooltip {
    padding: 5px 10px;
    border-radius: 11px;
}

.chart .ring-label {
    text-transform: uppercase;
}