.flag {
  font-size: 9px;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 10px;
  position: relative;
  vertical-align: top;
  margin-top: -2px;
  left: 5px;
  color: white;

  &--new {
    background: var(--color-red);
  }

  &--changed {
    background: var(--color-blue);
  }
}
