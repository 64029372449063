
.root{
  height: 60vh;
  display: flex;
  align-items: center;
}
.area {
  position: relative;
  width: 100%;
  height: 30vh;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px 9px 12px;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 1160px) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}

.title {
  text-align: left;
  font-weight: bold;
  font-size: xx-large;
  width: 28vw;
  color: #000;
  min-height: 270px;
  max-width: 500px;
   @media (max-width: 640px) {
    width: 100%;
    min-height: 325px;
  }
}

.main-text {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  opacity: 0;
  width: 25vw;
  max-width: 400px;
  color: #000;
  opacity: 0;
 
  @media (max-width: 640px) {
    width: 100%;
  }
}

.area-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.rectangle-area {
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 48px;
  padding-left: 36px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;

  @media (min-width: 640px) {
    padding-top: 0;
    flex-direction: row;
  }
}

.rectangle-pink {
  width: 70px;
  height: 100%;
  background-color: #d6007f;
  position: relative;
  transform: rotate(45deg);
  transition: all 0.3s;
  left: 0;
  @media (max-width: 1160px) {
    max-height: 300px;

    @media (max-width: 640px) {
      height: 35%;
      width: 60px;
      left: calc(50% - 30px);
    }
  }
}

.rectangle-blue {
  width: 70px;
  height: 100%;
  background-color: #05baee;
  position: relative;
  transform: rotate(-45deg);
  transition: all 0.3s ease;
  right: 0;
  @media (max-width: 1160px) {
    max-height: 300px;
    @media (max-width: 640px) {
      height: 35%;
      width: 60px;
      left: calc(50% - 30px);
      right: unset;
    }
  }
}

.square-blue {
  position: absolute;
  width: 100%;
  height: 70px;
  background-color: #040077;
  top: calc(50% - 35px);
  opacity: 0;
  transition: all 0.5s ease;

  @media (max-width: 640px) {
    top: calc(50% - 30px);
    height: 60px;
  }
}

.area:hover {
  .main-text {
    opacity: 1 !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .rectangle-pink {
    left: calc(50% - 35px);

    @media (max-width: 640px) {
      left: calc(50% - 30px);
      top: 25%;
    }
  }

  .rectangle-blue {
    right: calc(50% - 35px);

    @media (max-width: 640px) {
      left: calc(50% - 30px);
      right: unset;
      bottom: 40%;
    }
  }

  .square-blue {
    opacity: 1 !important;
  }
}

.area:not(:hover) {
  .square-blue {
    opacity: 0;
    transition: opacity 0s ease-in-out;
  }

  .main-text {
    opacity: 0;
    transition: opacity 0s ease-in-out;
  }
}
