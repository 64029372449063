.radar-grid {
    position: relative;
    margin-bottom: 50px;
    color: white;
    // display: none;
}

@media screen and (min-width: 800px) {
    .radar-grid {
        display: block;
    }
}

.radar-grid .quadrant-label {
    position: absolute;
    width: 20%;
}

.quadrant-label .split {
    font-size: 12 px;
    text-transform: uppercase;
}

.quadrant-label hr {
    width: 100%;
    margin: 10px 0 10px 0;
}

.quadrant-label .description {
    font-size: 14px;
    color: #a6b1bb
}

.quadrant-label .icon-link {
    font-size:  12px;
}

.quadrant-label .icon-link .icon {
    background-size:  18px 18px;
    width: 18px;
    height: 18px;
}

.radar-grid .radar-legend {
    position: absolute;
    width: 15%;
    right: 0;
    top: 45%;
}

.radar-legend .wrapper {
    margin-bottom: 10px;
}

.radar-legend .icon {

    background-position: center;
    margin-right: 5px;
}

.icon-link:hover .zoom-in-text {
    border-radius: 50%;
    animation: pulse 1s infinite;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;       
  }

  @keyframes pulse {
        
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(229, 62, 62, 1);
    }
  
    70% {
      transform: scale(1.01);
      box-shadow: 0 0 0 10px rgba(229, 62, 62, 0);
    }
  
    100% {
      transform: scale(1);
    }
  }

  @media only screen and (max-width: 1114px) {
    /* For mobile phones: */
    .quadrant-label {
     display: none;
    }
  }