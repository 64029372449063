.tags-modal {
    font-size: 16px;
    position: relative;
    padding: 50px;

    &__close-button {
        position: absolute;
        top: 10px;
        right: 30px;
        transform: translateX(20px);
    }

    &__title {
        width: 100%;
    }
    
    &__list {
        padding: 15px 0 0;
        list-style-type: none;
        margin: 0;
    }
    
    &__list-item {
        margin-bottom: 5px;
        display: flex;
    }

    &__list-item-checkbox {
        margin-right: 10px;
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}