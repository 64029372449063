@media only screen and (max-width: 800px) {
    /* For mobile phones: */
    .test {
     display: none;
    }
  }
  
.nav_display{

  @media (max-width: 800px) {
    width: 90%;
    justify-content: space-between;
    align-items: center;
  }
}

.about-us-link {
  display: none;

  @media (max-width: 800px) {
    position: initial !important;
    left: initial !important;
    bottom: initial !important;
    display: flex !important; 
  }
}
