:root {
  // --color-gray-dark: #475157;
  --color-gray-dark-alt: #4f585e;
  --color-gray-dark-alt2: #434d53;
  --color-gray-normal: black;
  --color-gray-light: #7d878d;
  --color-gray-light-alt: #adadad;
  --elyadata-blue: #05baee;
  --elyadata-pink: #d6007f;
  --elyadata-dark-blue: #040077;
  --color-white: black;
  --color-green: #5cb449;
  --color-orange: #faa03d;
  --color-blue: #40a7d1;
  --color-marine: #688190;
  --color-red: #f1235a;
  --color-brand: #f59134;
  --until-sm: 30em;
  --until-md: 48em;
  --until-lg: 61.875em;
  --until-xl: 75em;
}

body {
  background: var(--color-gray-dark);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "DIN";
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;

  & h1 {
    color: blue;
  }
}

#root {
  min-height: 100vh;
  overflow: hidden;
}

body::-webkit-scrollbar {
  width: 1px;
}
 

 


@import "./styles/main.scss";
